import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Dashboard/Home.vue";
import Login from "../views/Login/Login.vue";
import MeuPerfil from "../views/Perfil/MeuPerfil.vue";
import DadosCadastrais from "../views/Perfil/DadosCadastrais.vue";

Vue.use(VueRouter);

const routes = [
  /*
  {
    path: "/checkout",
    name: "checkout",
    props: true,
    component: () =>
      import( "../views/Checkout/Index.vue")
  },
  */
  {
    path: "/enquete/:id",
    name: "enquete",
    props: {
      default: true,
      dataset: true
    },
    component: () =>
      import(/* webpackChunkName: "obrigado" */ "../views/Site/Enquete.vue"),
  },
  {
    path: "/encerrado",
    name: "encerrado",
    props: {
      default: true,
      dataset: true
    },
    component: () =>
      import(/* webpackChunkName: "obrigado" */ "../views/Site/Completion.vue"),
  },
  {
    path: "/",
    name: "Dashboard",
    component: () =>
    import(/* webpackChunkName: "relatorios" */ "../views/Enquetes/List.vue"),
    meta: {
      login: true,
      role: 'Gestor'
    },
  },
  {
    path: "/relatorio/enquete/:id",
    name: "Relatorios",
    component: () =>
    import(/* webpackChunkName: "relatorios" */ "../views/Enquetes/Relatorio.vue"),
    meta: {
      login: true,
      role: 'Gestor'
    }
  },
    {
      path: "/bigdata",
      name: "BigData",
      component: () =>
      import(/* webpackChunkName: "relatorios" */ "../views/Enquetes/BigData.vue"),
      meta: {
        login: true,
        role: 'Gestor'
      },
  },


  
  {
    path: "/enquete/editar/:id",
    name: "editar-enquete",
    component: () =>
    import(/* webpackChunkName: "relatorios" */ "../views/Enquetes/Index.vue"),
    meta: {
      login: true,
      role: 'Gestor'
    },
  },
  {
    path: "/recuperar/:token",
    name: "recuperar",
    component: () =>
      import(
        /* webpackChunkName: "configuracoes" */ "../views/Login/Recuperar.vue"
      ),
      props: true,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    props: true,
  }, 

    {
    path: "/erro",
    name: "erro", 
    component: () =>
    import(
      /* webpackChunkName: "cadastro" */ "../views/Site/Cancel.vue"
    ),
    props: true,
  },
  {
    path: "/meu-perfil",
    name: "perfil",
    component: MeuPerfil,
    props: true,
    meta: {
      login: true,
    },
    children: [
      {
        path: "",
        name: "meuperfil",
        component: DadosCadastrais,
      },
      {
        path: "configuracoes",
        name: "configuracoes",
        meta: {
          role: 'Aluno',
        },
        component: () =>
          import(
            /* webpackChunkName: "configuracoes" */ "../views/Perfil/Configuracoes.vue"
          ),
      },
    ],
  },

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return window.scrollTo({ top: 0, behavior: "smooth" });
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.login)) {
    if (!window.sessionStorage.token) {
      next("/login");
    } else {
      next();
    }
  } else {
    next();
  }
});


export default router;
