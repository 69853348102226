<template>
  <div id="login">
    <transition mode="in-out">
      <div class="container-fluid">
        <div class="row">
          <div
            class="col-12 d-flex justify-content-center align-content-center"
          >
            <img
              src="@/assets/logo_white.png"
              class="img-fluid"
              width="200"
              alt="logo"
            />
          </div>
        </div>

        <div class="row" v-if="asLogin" key="login">
          <div class="col form_padrao shadow-sm">
            <h5 class="pb-4 text-uppercase text-center">Acesse sua conta</h5>
            <form action @submit.prevent="login">
              <div class="form-group flutuante">
                <input
                  type="text"
                  name="email"
                  id="email"
                  required
                  v-model="userData.user"
                />
                <label for="email">Seu E-mail</label>
              </div>
              <div class="form-group flutuante">
                <input
                  type="password"
                  name="senha"
                  id="senha"
                  required
                  v-model="userData.password"
                />
                <label for="senha">Senha</label>
              </div>
              <div class="form-group">
                <div
                  class="alert alert-danger border-0 text-center"
                  v-if="error"
                  role="alert"
                >
                  {{
                    errorMsg == 401
                      ? "Usuário ou senha inválidos"
                      : "falha ao autenticar"
                  }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-7 col-6 align-self-center font-13">
                  <a
                    href="javascript:void(0)"
                    @click="asLogin = false"
                    class="esqueci"
                    >Esqueci minha senha</a
                  >
                </div>
                <div class="col-md-5 col-6">
                  <button
                    type="submit"
                    class="btn btn-verde p-0"
                    :disabled="loading"
                  >
                    <span
                      class="spinner-grow spinner-grow-sm pr-2"
                      role="status"
                      aria-hidden="true"
                      v-show="loading"
                    ></span>
                    <span v-if="loading">Aguarde</span>
                    <span v-else>Entrar</span>
                  </button>
                </div>
              </div>
            </form>
            <hr class="text-muted" />

<!--             <div class="py-3 d-flex justify-content-between">
              <button
                class="btn btn-outline-danger mr-2 w-100"
                @click="loginSocial('google')"
              >
                Google
              </button>
              <button
                class="btn btn-outline-primary w-100"
                @click="loginSocial('facebook')"
              >
                Facebook
              </button>
            </div>
 
            <p class="text-center small">
              Ainda não é cadastrado ?
              <router-link :to="{ path: '/cadastre-se' }">Crie sua conta.</router-link>
            </p>
            -->
          </div>
        </div>
        <div class="row" v-else key="esqueci">
          <div class="col form_padrao shadow-sm">
            <h5 class="pb-4 text-uppercase text-center">Esqueci minha senha</h5>
            <form action @submit.prevent="recuperarSenha">
              <div class="form-group flutuante">
                <input
                  type="text"
                  name="email"
                  id="email"
                  required
                  v-model="userData.userName"
                />
                <label for="email">Informe seu e-mail</label>
              </div>
              <div class="row">
                <div class="col-md-7 col-6 align-self-center font-13">
                  <a
                    href="javascript:void(0)"
                    @click="asLogin = true"
                    class="esqueci d-flex align-items-center"
                  >
                    <i class="material-icons pr-1">arrow_left</i> Voltar para
                    login
                  </a>
                </div>
                <div class="col-md-5 col-6">
                  <button
                    type="submit"
                    class="btn btn-verde p-0"
                    :disabled="loading"
                  >
                    <span
                      class="spinner-grow spinner-grow-sm pr-2"
                      role="status"
                      aria-hidden="true"
                      v-show="loading"
                    ></span>
                    <span v-if="loading">Aguarde</span>
                    <span v-else>Enviar</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </transition>
    <Chat />
  </div>
</template>

<script>
import axios from "axios";
import Chat from "@/components/Chat/index.vue";
import {isUserAllowedToBypassTranspesValidation, validateUserDocInServer} from "@/services";

export default {
  name: "Login",
  components: {Chat},
  data() {
    return {
      asLogin: true,
      userData: {
        user: "",
        password: "",
      },
      loading: false,
      error: false,
      errorMsg: "",
    };
  },

  methods: {
    // TODO: Fix social login endpoint
    loginSocial(value) {
      switch (value) {
        case "facebook":
          window.open(
            this.$apiURL + "/api/user/loginSocial?tipo=facebook",
            "_blank"
          );
          break;
        case "google":
          window.open(
            this.$apiURL + "/api/user/loginSocial?tipo=google",
            "_blank"
          );
          break;
        default:
          break;
      }
    },
    recuperarSenha() {
      this.loading = true;
      this.error = false;
      this.$http
        .get(`api/user/recSenha?type=aluno&codigo=${this.userData.userName}`)
        .then(() => {
          this.$toast.open({
            message: "Solicitação enviada ! Verifique sua caixa de e-mail.",
            type: "success",
            duration: 4000,
          });
          this.loading = false;
          this.asLogin = true;
          this.userData = {};
        })
        .catch(() => {
          this.$toast.open({
            message: "E-mail não cadastrado!",
            type: "error",
          });
          this.loading = false;
          this.error = true;
        });
    },
    login() {
      this.loading = true;
      this.error = false;
      this.$http
        .login(this.userData)
        .then(async (response) => {

          // Only validates transpes if user is not allowed to bypass validation
          if (!isUserAllowedToBypassTranspesValidation(response.data.email)) {
            // TODO: move this validation to the backend using whitelabel token
            // Transpes begin validation
            if (!response.data.cpf) {
              throw new Error("CPF inválido do usuário! Contato seu supervisor.");
            }
            
            if (this.$validDoc === 1) {
              this.$toast.open({
                message: 'Validando usuário na '+this.$titleSite+'...',
                type: 'info',
                duration: 9000,
                dismissable: false
              })

              const cpfNumbers = response.data.cpf.replace(/\D/g, "");
              const isTranspesValidUser = await validateUserDocInServer(cpfNumbers)

              if (!isTranspesValidUser) {
                throw new Error("Não foi possível contatar o servidor da Transpes, por favor tente mais tarde.");
              }
              if (isTranspesValidUser.data?.status === 'error') {
                throw new Error("Usuário não autorizado na Transpes! Contate seu supervisor.");
              } else {
                this.$toast.open({
                  message: 'Usuário validado!',
                  type: 'success',
                  duration: 4000,
                  dismissable: true
                })
              }
            }
          }

          //Set Token
          window.sessionStorage.token = `Bearer ${response.data.api_key}`;
          //Set user data
          window.sessionStorage.userData = JSON.stringify(response.data);
          this.$store.commit("UPDATE_USER", response.data);
        })
        .then(() => {
          this.loading = false;
          if (sessionStorage.cursoId) {
            this.$router.push({
              name: "curso",
              params: { id: sessionStorage.cursoId },
            });
            sessionStorage.cursoId = ""
          } else {
            this.$router.push({ name: "Dashboard" });
          }
        })
        .catch((error) => {
          if (error.message.includes(401)) {
            this.$toast.error("Usuário ou senha inválidos!");
          } else {
            this.$toast.open({
              message: error.message,
              type: "error",
            });
          }
          this.loading = false;
          this.error = true;
          this.errorMsg = error.response.status;
        });
    },
  },
  created() {
    if (this.$route.query.id) {
      window.sessionStorage.cursoId = this.$route.query.id;
    }
    else{
       sessionStorage.cursoId = ""
    }

    document.title = this.$titleSite;
    window.addEventListener("offline", () => {
      this.$toast.open({
        message: "Sem conexão com a internet.",
        type: "error",
        duration: 3000,
      });
    });
  },
  mounted() {
    if (this.$route.query.code) {
      // TODO: Fix social login endpoint
      axios
        .get(
          "https://wpp.academy/univille/admin/api/user/loginSocial?code=" +
            this.$route.query.code +
            "&tipo=" +
            this.$route.query.tipo
        )
        .then((response) => {
          window.sessionStorage.token = `Bearer ${response.data.api_key}`;
          window.sessionStorage.userData = JSON.stringify(response.data);
          this.$store.commit("UPDATE_USER", response.data);
        })
        .then(() => {
          this.loading = false;
          if (sessionStorage.cursoId) {
            this.$router.push({
              name: "curso",
              params: { id: sessionStorage.cursoId },
            });
            sessionStorage.cursoId = ""
          } else {
            this.$router.push({ name: "Dashboard" });
          }
        });
    }
  },
};
</script>

<style lang="scss" scoped>
  #login .form_padrao{
    margin-top: 5vh;
  }
</style>
